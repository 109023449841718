@keyframes scaleAnim {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}

.floatanim {
  animation: floatAnim 3s ease-in-out infinite;
}
@keyframes wobbleAnim {
  0%, 100% {
    transform: translateX(0%);
  }
  25% {
    transform: translateX(-2%);
  }
  50% {
    transform: translateX(1%);
  }
  75% {
    transform: translateX(-1%);
  }
}
#tick{animation: foatwobble 4s ease-in-out infinite;
}
#bu,#de, #a {   animation: scaleAnim 4s ease-in-out infinite;

}
.wobbleanim {
  animation: wobbleAnim 4s ease-in-out infinite;
}


.scaleanim {
  animation: scaleAnim 3s ease-in-out infinite;
}
@keyframes wobbleAnim {
  0%, 100% {
    transform: rotateY(0deg);
  }
  25% {
    transform: rotateY(-5deg);
  }
  50% {
    transform: rotateY(25deg);
  }
  75% {
    transform: rotateY(-10deg);
  }
}
@keyframes wobbleAnim1 {
  0%, 100% {
    transform: rotateY(0deg);
  }
  25% {
    transform: rotateY(-5deg);
  }
  50% {
    transform: rotateY(25deg);
  }
  75% {
    transform: rotate(-0.5deg);
  }
}
@keyframes foatwobble {
  0%, 100% {
    transform: rotateX(0deg);
    opacity: 1; /* Fully visible */
  }
  25% {
    transform: rotateX(-5deg);
    opacity: 0.85; /* Slightly transparent */
  }
  50% {
    transform: rotateX(25deg);
    opacity: 0.9; /* More transparent */
  }
  75% {
    transform: rotateX(-10deg);
    opacity: 0.85; /* Slightly transparent */
  }
}
@keyframes rotate {
  0%, 100% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(1deg) translateY(-10px);
  }
}
@keyframes bubble2Animation {
  /* Define your keyframes here */
}

.animate {
  animation: bubble2Animation 3s ease-in-out;
}
#bubble1 {
  animation: wobbleAnim1 3s ease-in-out infinite -0.05s;
}

#bubble2 {
  animation: wobbleAnim1 3s ease-in-out infinite -0.02s; /* Adds a 1-second delay */
}
#bubble {
  animation: wobbleAnim1 3s ease-in-out infinite -0.08s; /* Adds a 1-second delay */
}

#door {
  animation: rotate 6s infinite ease-in-out;

}

#letter {
  animation: wobbleAnim 3s ease-in-out infinite;
}
