.sidebar li {
  margin-bottom: 2em;
}
.sidebar a:hover {
  display: inline-block;
  text-decoration: none;
  transform: translate(8px);
}
.sidebar svg {
  margin-right: 5px;
}
@media only screen and (max-width: 600px) {
  .sidebar {
    z-index: 111;
  }
}
.sidebar {
  z-index: 111;
}