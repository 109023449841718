
@font-face {
  font-family: 'SF Display';
  font-display: swap;
  src: url('./assets/fonts/SF-Display.otf') format('opentype');
}
@font-face {
  font-family: 'SF';
  font-display: swap;
  src: url('./assets/fonts/SF-Pro-Medium.otf') format('opentype');
}

.cornerstone {font-family: 'SF Display';
  letter-spacing: 3px;}
 


.SF {font-family: 'SF';
}

.waiting {
  cursor: wait !important;
}
/* width */
::-webkit-scrollbar {
  width: 7px;
}
.boo{background-color: #000000;
z-index: 2000;}
:root {
  --button-menu: #ffffff00;
  --background-color: #F8EDDC;
  --text-color: #131f3a;
  --text-color-main: #48270f;
  --footer-color-main: #48270f;
  --nav-text-color: #3a3a3a;
  --button-color: #f8f8f8;
  --navbar-background-color: #d86d2054;
  --navbar-background-color1: #d86d202d;
  --borders-color: #000000;
  --navbar-border-color: transparent;
  --image-url: url('/public/08.png');
}
body.dark-theme {
  --button-menu: #aaa11f00;
  --text-color-main: #fff;
  --footer-color-main: #D86D20;
  --button-color: rgb(255, 255, 255);
  --background-color: #000000;
  --nav-text-color: #ffffff;
  --text-color: #ffffff;
  --navbar-background-color: #34343463;
  --navbar-border-color: #333;
  --borders-color: #ffffff;
  --image-url: url('/public/07.png');
}

/* Hide the custom toggler by default */
.custom-toggler {
  display: none;
}
.btn-danger, .badge-danger{background-color: #D86D20;
border-radius: 12px;
margin-bottom: 2rem;
}

.bg-footer{background-color: var(--footer-color-main);}
.custom-bg{ background-color:#D86D20 ;}



/* Only show the toggler below 990px */
@media (max-width: 990px) {
  #basic-navbar-nav {
    padding: 1rem;
    z-index: -4;
  scale: 0.95;
  color: var(--text-color);
    border-radius: 15px;
    background-color: var(--background-color);
    position: absolute;
    top: 95%;
    left: 0;
    width: 100%;
  }
  .custom-toggler {
      display: block;
      margin-top: 0;
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg) scale(1);
  }
  to {
    transform: rotate(360deg) scale(1.2);
  }
}

.login-button{
background-color: transparent;
border: solid 1px var(--text-color);
color: var(--text-color);
left: 0;
margin-top: 0.5rem;
font-weight: 600;
text-transform: uppercase;
}

.login-button:hover{
  background-color: #4d4d4d14;
  border: solid 1px var(--text-color);
  color: var(--text-color);
  left: 0;

  font-weight: 600;
  text-transform: uppercase;
  }
.theme-toggle-dark,
.theme-toggle-light {
  animation: spin 0.5s ease forwards;
}
.themechangers{
display: flex;
flex-direction: flex-start;
float: left;
left: 0;
margin-left: 0;
  height: 32px;
  fill: var(--text-color);}

.menu-toggler{fill: var(--text-color);}
  .toggle-toggle {
    display: flex;
    width: 40%;
    margin-left: 0;
    float: left;
  }
  
body {font-family: 'SF';
  background-color: var(--background-color);
color: var(--text-color);
}
.contact-icons:hover {
  color: rgb(0, 166, 0) ; /* Change to your desired hover color */
  transform: scale(1.6); /* Slightly enlarge the icons on hover */
  transition: color 0.3s ease, transform 0.3s ease; /* Smooth transition for color and scale */
}
.contact-icons {
  color: #D86D20; /* Change to your desired hover color */
  transform: scale(1.3); /* Slightly enlarge the icons on hover */
}
.review-container {
  border: 1px solid #ffffff; /* Adds a subtle border around the card and button */
 margin: 1rem;
  border-radius: 12px;
 padding: 1rem;

  display: flex; /* Enables flexbox layout */
  flex-direction: column; /* Stacks items vertically */
  background-color: #f9f9f9; /* Light background color for the container */
}
.grayscale {
  filter: grayscale(100%); /* Convert image to grayscale */
}

.btn-approve {
  align-self: center; /* Center the button horizontally */
  width: fit-content; /* Make button width as per content */
}

.collection-images {
  box-shadow: 0 4px 8px rgba(253, 114, 114, 0.114);
  width: auto; /* Adjust as needed */
  height: 200px; /* Adjust as needed */
  margin: 1rem;
  border-radius: 12px;
  cursor: pointer;
  transition: opacity 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition */
}

.collection-images:hover {
  opacity: 0.9;
  transform: scale(1.03); /* Slight increase in size */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Enhanced shadow for a 'lifted' effect */
}

/* Gallery.css */
.left-button, .right-button {
  /* Button styles */
  cursor: pointer;
  transition: transform 0.2s ease;
}

@keyframes wobble-left {
  0% { transform: translateX(0); }
  15% { transform: translateX(-5px) rotate(-5deg); }
  30% { transform: translateX(5px) rotate(5deg); }
  45% { transform: translateX(-5px) rotate(-5deg); }
  60% { transform: translateX(5px) rotate(5deg); }
  75% { transform: translateX(-5px) rotate(-5deg); }
  100% { transform: translateX(0); }
}

@keyframes wobble-right {
  0% { transform: translateX(0); }
  15% { transform: translateX(5px) rotate(5deg); }
  30% { transform: translateX(-5px) rotate(-5deg); }
  45% { transform: translateX(5px) rotate(5deg); }
  60% { transform: translateX(-5px) rotate(-5deg); }
  75% { transform: translateX(5px) rotate(5deg); }
  100% { transform: translateX(0); }
}

.wobble-left {
  animation: wobble-left 0.5s ease forwards;
}

.wobble-right {
  animation: wobble-right 0.5s ease forwards;
}





.modal {
  transform: scale(1.4);
  margin-top: 12rem;
  top: 0;
}
/* Only show the toggler below 990px */
@media (max-width: 800px) {
  .img-fluid{
margin: 0.6rem;
top: -3rem;}
  
  .modal {
    transform: scale(1);

  }
}
.modal-content {
  color: var(--text-color);
  background-color: #ffffff8e;
  backdrop-filter: blur(2px);
  border-radius: 12px;
}

.modal-image {

  border-radius: 10px;
  width: 100%;
    box-shadow: 0 4px 8px #48270f86;  
  object-fit: contain;
}

.right-button,
.left-button {
  font-weight: 500;
  font-size: 12px;
  color: #ffffff;

  scale: 0.9;
  border: solid 1px #fff;
  background-color: #7A3B1F;
  margin: 0.5rem;
  /* Adding box shadow */
    box-shadow: 0 4px 8px #48270f86;  /* Example shadow: horizontal offset, vertical offset, blur radius, color */
}














.nav-toggler-burger {
  background-color: var(--button-menu)!important; 

}


.logo {padding-top: 0.3rem;
 margin-left: 0.5rem;
  animation: heartbeat 3s infinite;
  fill: var(--text-color);

}
@keyframes heartbeat {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.05) rotate(-4deg);
  }
  40% {
    transform: scale(1);
  }
  60% {
    transform: scale(1.082) rotate(2deg);
  }
  80% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}


.badges {  animation: heartbeat 3s infinite;
}
.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; /* Adjust as necessary */
}


.navbar .navbar-collapse .nav-link.nav-link-custom {
 margin-right: 0;
  z-index: 1020; 
  color: var(--nav-text-color) !important; /* Change this to your desired text color */
}



.zoomed-in {
  transform: scale(1.5);
  cursor: zoom-out;
}

.shadow{
  
  border-radius: 12px;
  background-color: var(--background-color);
  color: var(--text-color);
  border: 2px var(--borders-color) ;


}
.text-dangen{ 
  
  color: var(--text-color-main);
 }


 .nav-toggler-burger {
  background-color: var(--menu-svg); 
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(134, 132, 132);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #FF5722;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: #239400;
}
.navbarCustom {
 backdrop-filter: blur(4px);
    z-index: 1020; 
    background-color: var(--navbar-background-color);
    border-bottom-color: var(--navbar-border-color);

  box-shadow: 0 0 8px #b300004b;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  transition: all 300ms ease-in-out;
}
.navbarCustom1 {
  backdrop-filter: blur(4px);
     z-index: 1020; 
     background-color: var(--navbar-background-color1);
     border-bottom-color: var(--navbar-border-color);
 
   box-shadow: 0 0 8px #b300004b;
   border-bottom-left-radius: 15px;
   border-bottom-right-radius: 15px;
   transition: all 300ms ease-in-out;
 }

.sticky-top{margin-top: 0;}
.custom-overflow {
  overflow-x: auto;
  overflow-y: hidden;
}
.custom-overflow {
  scroll-behavior: smooth;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.custom-overflow ::-webkit-scrollbar,
.custom-review-card ::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.custom-overflow,
.custom-review-card {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.service-price{margin: 1rem;
}
.service-title{margin: 1rem;
  font-family: SF Display;
  color: var(--footer-color-main);
}




.custom-review-card {
  height: 60px;
  overflow-y: auto;
  overflow-x: hidden;
}
.headerr{

  
  margin-right: 28%;}
.orderTable tbody {
  display: block;
  max-height: 350px;
  overflow-y: scroll;
}
.orderTable thead,
.orderTable tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
.email-cell {
  width: 30%;
}


.service-button {
  position: relative;
  width: 100%;
  margin-top: 20px; /* Adjust the top margin as needed */
  margin-bottom: 20px; /* Adjust the bottom margin as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #D86D20; /* Adjust background color as needed */
  color: #fff; /* Adjust text color as needed */
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.service-button:hover {
  background-color: #c82333; /* Adjust hover background color as needed */
  transform: scale(1.05); /* Adjust scale factor for hover effect */
}
.services{
 
  margin-top: 2rem;
  margin-bottom: 2rem;
 }
.about-section {
  
  margin-bottom: 7rem;

}

.image-aboutus-section {
  position: relative;
}

.img-fluid{
  box-shadow: 0 4px 8px #48270f86; 

  overflow: hidden;
  border-radius: 15px;
  animation: infinite floatAnimation ease 3s;
  position: relative;
  z-index: 1; /* Ensure the image is below the overlay */
  width: 95%; /* Ensure it fills the container */
  height: auto; /* Maintain aspect ratio */
}

@keyframes floatAnimation {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px); /* Adjusted for a noticeable effect */
  }
}



.about-text {
  font-size: 18px; /* Adjust text size as needed */
}

.cursor-particle {
  position: absolute;
  pointer-events: none;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #ffffff; /* or any color you prefer */
  opacity: 0.8;
  z-index: 10000; /* Ensure it's above most elements */
  transition: transform 0.5s ease-out, opacity 0.5s ease-out;
  transform: scale(0) translate(-50%, -50%);
  animation: fadeAndGrow 0.5s forwards;
}

@keyframes fadeAndGrow {
  to {
    transform: scale(2) translate(-50%, -50%);
    opacity: 0;
  }
}
.HeaderImage{
    box-shadow: 0 4px 8px #48270f86;  

  overflow: hidden;
  border-radius: 15px;
  animation: infinite floatAnimation ease 3s;
  border-radius: 1rem;
margin-left: 0.5rem;}


.custom-overflow {
  overflow-x: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

.custom-overflow::-webkit-scrollbar { 
  display: none; /* Chrome, Safari, Opera*/
}


.review-card {
  border: 1px solid #ccc;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
}

.review-header {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.review-header img {
  margin-right: 15px;
  border-radius: 50%;
}

.review-header div {
  display: flex;
  flex-direction: column;
}

.review-header h5 {
  margin: 0;
  color: #333;
}

.review-header p {
  margin: 4px 0 0;
  color: #666;
}

.review-body p {
  margin: 0 0 10px;
  color: #555;
}

.review-body div {
  font-weight: bold;
  color: #f4b400; /* Gold color for the rating */
}

.approve-review-btn {
  padding: 10px 20px;
  background-color: #4caf50; /* Green background */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  align-self: flex-start;
}

.approve-review-btn:hover {
  background-color: #45a049; /* Darker green */
}
